import {useEffect, useState} from "react";

import MovieCard from "./MovieCard";

import './App.css'
import SearchIcon from './search.svg'
const API_URL = "https://www.omdbapi.com/?apikey=392b63e7";

const App = () => {

  const [movies, setMovies] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const searchMovie = async (title) => {
    const response = await fetch(`${API_URL}&s=${title}`);
    const data = await response.json();

    setMovies(data.Search);
  }

  useEffect(() => {
    searchMovie('Spiderman')
  }, []);

  return (
    <div className={"app"}>
      <h1>Библиотека фильмов</h1>
      <div className={"search"}>
        <input
          placeholder={"Поиск"}
          value={searchTerm}
          type={"text"}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              searchMovie(searchTerm);
            }
          }}
        />
        <img
          src={SearchIcon}
          alt={"Search"}
          onClick={() => searchMovie(searchTerm)}
        />
      </div>
      {movies?.length > 0 ? (
        <div className="container">
          {movies.map((movie) => (
            <MovieCard movie={movie}/>
          ))}
        </div>
      ) : (
        <div className="empty">
          <h2>Фильмы не найдены</h2>
        </div>
      )}
    </div>
  )
}

export default App;
